import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Home1 from "../../images/slider1.jpg";
import Home2 from "../../images/slider2.jpg";
import Home4 from "../../images/slider3.jpg";
import "./index.css"
import { BACKEND_Image_URL } from '../../env';

function Sliders({ sliderData }) {
    // const images = [
    //     Home1,
    //     Home2,
    //     Home4,
    // ];

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    return (
        <div className='slider-container' >
            {/* <h2>Image Slider</h2> */}
            <Slider {...settings}>
                {sliderData?.map((image, index) => (
                    <div key={index}>
                        <img src={`${BACKEND_Image_URL}${image.Image}`} alt={`Slide ${index}`} style={{ width: '100%', height: 'auto' }} />
                    </div>
                ))}
            </Slider>
        </div>
    );
}

export default Sliders;
