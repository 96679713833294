import { faSearch, faShoppingBag, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import Sidebar from '../../components/sidebar';
import { withAppContext } from '../../context';
import { BACKEND_Image_URL, BACKEND_URL } from '../../env';
import NotFound from "../../images/notFound.webp";
import "./index.css";

function Wishlist({ AllCategory, WishlistData, getWishlist, instagramData, AllProducts, }) {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [wishlistProducts, setWishlistProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [navbarSearchQuery, setNavbarSearchQuery] = useState("");
    const openSidebar = () => {
        setSidebarOpen(true);
    }

    const closeSidebar = () => {
        setSidebarOpen(false);
    }

    const storedLoginData = JSON.parse(localStorage.getItem("loginData"));
    const navigate = useNavigate();

    useEffect(() => {
        if (storedLoginData && storedLoginData.token) {
            axios.get(`${BACKEND_URL}/wishlist`, {
                headers: {
                    token: storedLoginData.token
                }
            })
                .then((response) => {
                    setWishlistProducts(response.data.wishlist);
                    setFilteredProducts(response.data.wishlist); // Initialize filtered products

                })
                .catch((error) => {
                    console.error("Error fetching wishlist:", error);
                });
        }
    }, []);

    useEffect(() => {
        // Filter products based on search query
        if (searchQuery) {
            const filtered = wishlistProducts.filter(product =>
                product.title.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setFilteredProducts(filtered);
        } else {
            setFilteredProducts(wishlistProducts);
        }
    }, [searchQuery, wishlistProducts]);
    const handleRemoveFromWishlist = (productId) => {
        axios.delete(`${BACKEND_URL}/wishlist`, {
            headers: {
                token: storedLoginData.token
            },
            data: { productId }
        })
            .then((response) => {
                // Remove the product from the local state
                setWishlistProducts(wishlistProducts.filter(product => product.id !== productId));
                setFilteredProducts(filteredProducts.filter(product => product.id !== productId));
                toast.success("Product Remove in Wishlist")
                getWishlist()
            })
            .catch((error) => {
                console.error("Error removing product from wishlist:", error);
            });
    };
    const handleAddToCartClick = (product) => {
        if (storedLoginData && storedLoginData.token) {
            let cart = JSON.parse(localStorage.getItem("cart")) || [];
            const productIndex = cart.findIndex((item) => item.id === product.id);

            if (productIndex === -1) {
                cart.push({ ...product, quantity: 1 });
            } else {
                cart[productIndex].quantity += 1;
            }

            localStorage.setItem("cart", JSON.stringify(cart));
            console.log("Product added to cart:", product);
        }
    };
    return (
        <div>
            <Navbar openSidebar={openSidebar} AllCategory={AllCategory} WishlistData={WishlistData} searchQuery={navbarSearchQuery} setSearchQuery={setNavbarSearchQuery} AllProducts={AllProducts} />
            <Sidebar closeSidebar={closeSidebar} sidebarOpen={sidebarOpen} />
            <div className="wishlits-container">
                <h1 className="wishlist-main-heading">DESSERT DROPPSHIPER WISHLIST</h1>
                <div className="heading-and-input-container">
                    <h1 style={{ fontFamily: "Kanit", fontSize: "20px" }}>Wishlist Product</h1>
                    <div className="wishlist-search-container">
                        <input
                            placeholder="Search In Products"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <FontAwesomeIcon icon={faSearch} color="black" />
                    </div>
                </div>
                <div className='wishlist-product-container'>
                    {filteredProducts.length > 0 ? (
                        filteredProducts.map((e, i) => (
                            <div key={i} className='wishlits-product-main'>
                                <img
                                    src={e.images?.length > 0 ?
                                        e.images[0].startsWith("products/http") || e.images[0].startsWith("products/https")
                                            ? e.images[0].replace("products/", "")
                                            : `${BACKEND_Image_URL}${e.images[0]}` : NotFound
                                    }
                                    // src={`${BACKEND_Image_URL}${e.images[0]}`}
                                    alt='' className='wishlist-product-image' onClick={() => navigate("/ProductDetail", { state: e })} />
                                <p className='wishlist-product-name'>{e.title}</p>
                                <p className='wishlist-product-price'>{e.price} Aed</p>
                                <div style={{ display: "flex" }}>
                                    <div className="wishlist-addcart" onClick={() => handleAddToCartClick(e)}>
                                        <FontAwesomeIcon icon={faShoppingBag} className="trending-icon" />
                                        <p>Add to Cart</p>
                                    </div>
                                    <div className="trending-addcart" style={{ marginLeft: 10 }} onClick={() => handleRemoveFromWishlist(e.id)}>
                                        <FontAwesomeIcon icon={faTimes} className="trending-icon" />
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>No products found</p>
                    )}
                </div>
            </div>
            <Footer instagramData={instagramData} />
        </div>
    )
}

export default withAppContext(Wishlist);
