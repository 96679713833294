import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/sidebar";
import { withAppContext } from "../../context";
import "./index.css";
function Privacy({ AllCategory, WishlistData, instagramData, AllProducts, }) {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const openSidebar = () => {
        setSidebarOpen(true);
    }

    const closeSidebar = () => {
        setSidebarOpen(false);
    }
    return (
        <>
            <Navbar openSidebar={openSidebar} AllCategory={AllCategory} WishlistData={WishlistData} searchQuery={searchQuery} setSearchQuery={setSearchQuery} AllProducts={AllProducts} />
            <Sidebar closeSidebar={closeSidebar} sidebarOpen={sidebarOpen} />
            <div className="privacy-policy-maindiv">

                <div className="privacy-policy">
                    <div className="policy-heading-container">
                        <h3>Privacy & Policy</h3>
                        {/* <h6>Last Modified: July 22 24</h6> */}
                    </div>
                    <div className="privacy-policy-div">
                        <h6 style={{ fontSize: "18px", fontWeight: "700" }}>Privacy Policy for Deserts Dropshipper</h6>
                        <h6 style={{ fontSize: "14px", fontWeight: "700" }}>Effective date:[22/6/24]</h6>
                        <p style={{ color: "black", fontSize: "14px", fontWeight: "500", marginTop: "10px" }}>

                            Welcome to Deserts Dropshipper! This Privacy Policy outlines how
                            we collect, use, disclose,  and protect your personal
                            information when you visit or use our website
                            <a href="#" style={{ marginLeft: "5px" }}>www.desertsdropshipper.com</a> (the "Site") and
                            avail of our services. By accessing or using our  Site and
                            services, you agree to the terms of this Privacy Policy.

                        </p>

                        <h6 style={{ fontSize: "18px", fontWeight: "700" }}>1. Information We Collect</h6>

                        <p style={{ fontSize: "14px", fontWeight: "500", marginTop: "10px" }}>
                            <strong style={{ paddingTop: "0.5%" }}>
                                Personal Information:{" "}
                            </strong>
                            {/*  */}
                            We may collect personal information that you voluntarily provide
                            to us  when you register on the Site, place an order,
                            subscribe to our newsletter, or contact us. This {" "}
                            information may include your name, email address, phone number,
                            shipping address, and  payment information.
                            {/* */}
                        </p>
                        <p style={{ fontSize: "14px", fontWeight: "500", marginTop: "10px" }}>
                            <strong style={{ paddingTop: "0.5%" }}>
                                Cookies and Tracking Technologies:{" "}
                            </strong>
                            {/*  */}
                            We use cookies and similar tracking technologies to {" "}
                            enhance your experience on our Site, analyze site usage, and
                            provide personalized content. You  can control cookies
                            through your browser settings.
                            {/* */}
                        </p>

                        <h6 style={{ fontSize: "18px", fontWeight: "700" }}>
                            2. How We Use Your Information
                        </h6>
                        <p style={{ fontSize: "14px", fontWeight: "500", marginTop: "10px" }}>

                            <strong >
                                We use the information we collect for the following purposes:
                            </strong>
                        </p>
                        <ul style={{ fontSize: "14px", fontWeight: "400", marginTop: "10px" }}>
                            <li>

                                To process and fulfill your orders, including delivery and
                                returns.

                            </li>
                            <li>

                                To communicate with you regarding your orders, inquiries, and
                                customer service  requests.

                            </li>
                            <li>

                                To send you promotional emails, newsletters, and special
                                offers that may be of interest to  you.

                            </li>
                            <li>

                                To improve our Site, services, and overall customer
                                experience.

                            </li>
                            <li>

                                To monitor and analyze site usage and trends to better
                                understand our customers'  preferences.

                            </li>
                            <li>

                                To prevent fraudulent transactions and ensure the security of
                                our Site.

                            </li>
                        </ul>
                        <h6 style={{ fontSize: "18px", fontWeight: "700" }}>
                            3. How We Share Your Information
                        </h6>
                        <p style={{ fontSize: "14px", fontWeight: "500", marginTop: "10px" }}>
                            <strong>
                                We may share your information with third parties in the following
                                circumstances:
                            </strong>
                        </p>
                        <ul style={{ fontSize: "14px", fontWeight: "400", marginTop: "10px" }}>
                            <li>
                                <p>
                                    <strong style={{ paddingTop: "0.5%" }}>
                                        Service Providers:{" "}
                                    </strong>

                                    We may share your information with trusted third-party
                                    service
                                    providers who assist us in operating our Site, conducting
                                    our business, and providing  services to you, such as
                                    payment processors and shipping carriers.

                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>Legal Requirment: </strong>

                                    We may disclose your information if required to do so by law
                                    or
                                    in response to valid requests by public authorities.

                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>Business Transfers: </strong>

                                    In the event of a merger, acquisition, or sale of all or a
                                    portion of  our assets, your information may be
                                    transferred to the acquiring entity.

                                </p>
                            </li>
                        </ul>
                        <h6 style={{ fontSize: "18px", fontWeight: "700" }}>4. Your choices</h6>
                        <p style={{ fontSize: "14px", fontWeight: "500", marginTop: "10px" }}>
                            <strong>
                                You have the following choices regarding your personal
                                information:
                            </strong>
                        </p>
                        <ul style={{ fontSize: "14px", fontWeight: "400", marginTop: "10px" }}>
                            <li>
                                <p>
                                    <strong style={{ paddingTop: "0.5%" }}>
                                        Access and Update:{" "}
                                    </strong>

                                    You can access and update your personal information by
                                    logging  into your account on the Site.

                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>opt-Out: </strong>

                                    You can opt-out of receiving promotional emails from us by
                                    following the  unsubscribe instructions in those
                                    emails.

                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>Cookies: </strong>

                                    You can control cookies through your browser settings and
                                    other tools.

                                </p>
                            </li>
                        </ul>

                        <h6 style={{ fontSize: "18px", fontWeight: "700" }}>5. Data Security</h6>
                        <p style={{ fontSize: "14px", fontWeight: "400", marginTop: "10px" }}>
                            {/* <strong> */}
                            We implement appropriate security measures to protect your
                            personal information from  unauthorized access, disclosure,
                            alteration, and destruction. However, please note that no method{" "}
                            of transmission over the Internet or electronic storage is
                            completely secure.
                            {/* </strong> */}
                        </p>
                        <h6 style={{ fontSize: "18px", fontWeight: "700" }}>6. Delivery Information</h6>
                        <ul style={{ fontSize: "14px", fontWeight: "400", marginTop: "10px" }}>
                            <li>

                                Delivery Time: 1 to 2 days

                            </li>
                            <li>Delivery Charges: 17 AED
                            </li>
                            <li> <strong>No Fulfillment Charges</strong>
                            </li>
                            <li><strong>Returns: Free</strong></li>

                        </ul>
                        {/* <ul style={{ fontSize: "14px", fontWeight: "400", marginTop: "10px" }}>
                            <li>
                                <p>Delivery Time: 1 to 2 days</p>
                            </li>
                            <li>
                                <p>Delivery Charges: 16 AED</p>
                            </li>
                            <li>
                                <p>No Fulfillment Charges </p>
                            </li>
                            <li>
                                <p>Returns: Free</p>
                            </li>
                        </ul> */}
                        <h6 style={{ fontSize: "18px", fontWeight: "700" }}>7. Children's Privacy</h6>
                        <p style={{ fontSize: "14px", fontWeight: "400", marginTop: "10px" }}>
                            Our Site is not intended for children under the age of 13. We do not knowingly collect or solicit personal information from children under 13. If we become aware that we have collected personal information from a child under 13, we will delete it immediately.
                        </p>
                        <h6 style={{ fontSize: "18px", fontWeight: "700" }}>8. Changes to This Privacy Policy</h6>
                        <p style={{ fontSize: "14px", fontWeight: "400", marginTop: "10px" }}>
                            We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any significant changes by posting the updated policy on our Site and updating the effective date.
                        </p>
                        <h6 style={{ fontSize: "18px", fontWeight: "700" }}>9. Contact Us</h6>
                        <p style={{ fontSize: "14px", fontWeight: "400", marginTop: "10px" }}>
                            If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at:
                            {/* Deserts Dropshipper */}
                        </p>
                        <p style={{ fontSize: "14px", fontWeight: "400", marginTop: "10px" }}>
                            <strong>
                                Email: {" "}
                            </strong>
                            info@desertsdropshipper.com
                        </p>
                        <p style={{ fontSize: "14px", fontWeight: "400", marginTop: "10px" }}>
                            <strong>
                                Phone: {" "}
                            </strong>
                            +971 [Insert Phone Number]
                        </p>
                        <p style={{ fontSize: "14px", fontWeight: "400", marginTop: "10px" }}>
                            Thank you for choosing Deserts Dropshipper. We value your trust and are committed to protecting your privacy
                        </p>
                    </div>
                </div>
            </div>
            <Footer instagramData={instagramData} />
        </>
    );
}

export default withAppContext(Privacy);
