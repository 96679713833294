import { faHome, faRemove, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/sidebar";
import { withAppContext } from '../../context';
import { BACKEND_Image_URL } from '../../env';
import NotFound from "../../images/notFound.webp";
import "./index.css";

function Cart({ AllCategory, WishlistData, instagramData, AllProducts, }) {
    const [cartItems, setCartItems] = useState([]);
    const [quantities, setQuantities] = useState({});
    const navigate = useNavigate();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    useEffect(() => {
        // Retrieve cart data from localStorage
        const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
        setCartItems(storedCart);

        // Initialize quantities based on the cart items
        const initialQuantities = {};
        storedCart.forEach((item, index) => {
            initialQuantities[index] = item.quantity || 1; // Retrieve quantity from item or default to 1
        });
        setQuantities(initialQuantities);
    }, []);

    const openSidebar = () => {
        setSidebarOpen(true);
    }

    const closeSidebar = () => {
        setSidebarOpen(false);
    }

    const handleQuantityChange = (index, value) => {
        const newQuantities = { ...quantities, [index]: value };
        setQuantities(newQuantities);

        // Update localStorage with the new quantities
        const updatedCartItems = cartItems.map((item, i) => ({
            ...item,
            quantity: newQuantities[i] || 1
        }));
        setCartItems(updatedCartItems);
        localStorage.setItem('cart', JSON.stringify(updatedCartItems));
    };

    const handleRemoveItem = (index) => {
        const updatedCartItems = cartItems.filter((_, i) => i !== index);
        setCartItems(updatedCartItems);
        localStorage.setItem('cart', JSON.stringify(updatedCartItems));
    };
    return (
        <div>
            <Navbar openSidebar={openSidebar} AllCategory={AllCategory} WishlistData={WishlistData} searchQuery={searchQuery} setSearchQuery={setSearchQuery} AllProducts={AllProducts} />
            <Sidebar closeSidebar={closeSidebar} sidebarOpen={sidebarOpen} />
            <div className="cart-container">
                <div className="bread-container">
                    <FontAwesomeIcon icon={faHome} className="bread-icon" />
                    <p className="bread-arrow">
                        {" > "}
                    </p>
                    <p className="bread-text">
                        Shopping Cart
                    </p>
                </div>
                <div>
                    <h1 className="shopping-cart-heading">
                        Shopping Cart
                    </h1>
                    <TableContainer component={Paper} className='table-scroll'>
                        <Table sx={{ minWidth: 900 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontFamily: "Kanit", fontSize: "14px", fontWeight: "700" }}>Images</TableCell>
                                    <TableCell style={{ fontFamily: "Kanit", fontSize: "14px", fontWeight: "700" }} align="left">Product Name</TableCell>
                                    <TableCell style={{ fontFamily: "Kanit", fontSize: "14px", fontWeight: "700" }} align="left">Sku</TableCell>
                                    <TableCell style={{ fontFamily: "Kanit", fontSize: "14px", fontWeight: "700" }} align="left">Quantity</TableCell>
                                    <TableCell style={{ fontFamily: "Kanit", fontSize: "14px", fontWeight: "700" }} align="left">Unit Price</TableCell>
                                    <TableCell style={{ fontFamily: "Kanit", fontSize: "14px", fontWeight: "700" }} align="left">Total Price</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {cartItems.length > 0 ? cartItems.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell component="th" scope="row">
                                            <img
                                                src={item.images?.length > 0 ?
                                                    item.images[0].startsWith("products/http") || item.images[0].startsWith("products/https")
                                                        ? item.images[0].replace("products/", "")
                                                        : `${BACKEND_Image_URL}${item.images[0]}` : NotFound
                                                }
                                                //  src={`${BACKEND_Image_URL}${item.images[0]}`} 
                                                alt={item.title} className="cart-product-image" onClick={() => navigate("/ProductDetail", { state: item })} />
                                        </TableCell>
                                        <TableCell style={{ fontFamily: "Kanit", fontSize: "14px", fontWeight: "500", color: "#222", cursor: "pointer" }} align="left" onClick={() => navigate("/ProductDetail", { state: item })}>
                                            {item.title}
                                        </TableCell>
                                        <TableCell style={{ fontFamily: "Kanit", fontSize: "14px", fontWeight: "500", color: "#878a99" }} align="left">{item.sku}</TableCell>
                                        <TableCell style={{ fontFamily: "Kanit", fontSize: "14px", fontWeight: "500", color: "#222" }} align="left">
                                            <div style={{ display: "flex" }}>
                                                <input
                                                    className="counter-input"
                                                    type="number"
                                                    value={quantities[index]}
                                                    onChange={(e) => handleQuantityChange(index, parseInt(e.target.value))}
                                                />
                                                <div className="cart-remove-main" onClick={() => handleRemoveItem(index)}>
                                                    <FontAwesomeIcon icon={faTrash} color="white" />
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell style={{ fontFamily: "Kanit", fontSize: "14px", fontWeight: "500", color: "#222" }} align="left">{item.price} Aed</TableCell>
                                        <TableCell style={{ fontFamily: "Kanit", fontSize: "14px", fontWeight: "500", color: "#222" }} align="left">
                                            {item.price * quantities[index]} Aed
                                        </TableCell>
                                    </TableRow>
                                )) : (
                                    <TableRow>
                                        <TableCell colSpan={6} align="center">
                                            No items in the cart.
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div className="cart-btn-container">
                    <button className="cart-continue-btn" onClick={() => navigate("/")}>Continue Shopping</button>
                    <button className="cart-checkout-btn" onClick={() => cartItems.length > 0 ? navigate("/Checkout") : null}>Checkout</button>
                </div>
            </div>
            <Footer instagramData={instagramData} />
        </div>
    );
}

export default withAppContext(Cart);
