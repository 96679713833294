import React, { useState } from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/sidebar";
import { withAppContext } from "../../context";
import { faClipboard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from "react-toastify";
import LoginPopup from "../../components/LoginPopup";
import "./index.css"
import Dropdowns from "../../components/Dropdown";
import axios from "axios";
import { BACKEND_URL } from "../../env";
import { CircularProgress } from "@mui/material";
function StoreIntegration({ AllCategory, WishlistData, instagramData, AllProducts, }) {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [login, setLogin] = useState(false);
    const [orderModal, setOrderModal] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [storeUrl, setStoreUrl] = useState("")
    const [storeName, setStoreName] = useState("")
    const [accessToken, setAccessToken] = useState("")
    const [email, setEmail] = useState("")
    const [orderStoreName, setOrderStoreName] = useState("")
    const [orderAccessToken, setOrderAccessToken] = useState("")
    const [startNumber, setStartNumber] = useState("")
    const [endNumber, setEndNumber] = useState("")
    const [category, setCategory] = useState("")
    const [loading, setLoading] = useState(false)
    const [orderLoading, setOrderLoading] = useState(false)
    const [webhooks, setWebhook] = useState("")
    const openSidebar = () => {
        setSidebarOpen(true);
    }

    const closeSidebar = () => {
        setSidebarOpen(false);
    }

    const token = JSON.parse(localStorage.getItem("loginData"))
    // Function to copy text to the clipboard
    // const copyToClipboard = (text) => {
    //     console.log(token);

    //     if (token?.token) {
    //         navigator.clipboard.writeText(text)
    //             .then(() => {
    //                 toast.success('Copied to clipboard!');
    //             })
    //             .catch((error) => {
    //                 console.error('Error copying text: ', error);
    //             });
    //     } else {
    //         setLogin(true)
    //     }
    // }
    // const OrderCopyToClipboard = (text) => {
    //     console.log(token);

    //     if (token?.token) {
    //         navigator.clipboard.writeText(text)
    //             .then(() => {
    //                 toast.success('Copied to clipboard!');
    //                 setOrderModal(true)
    //             })
    //             .catch((error) => {
    //                 console.error('Error copying text: ', error);
    //             });
    //     } else {
    //         setLogin(true)
    //     }
    // }

    // console.log(login);

    const openLoginModel = () => {
        setLogin(!login)
    }
    const closeOrderModel = () => {
        setOrderModal(!orderModal)
    }
    const handleCategorySelect = (categorys) => {
        // setSelectedCategory(category);
        setCategory(categorys); // Use this value as needed
    };

    const handleSubmit = () => {
        if (!storeName || !storeUrl || !accessToken || !category || !startNumber || !endNumber) {
            toast.error("Fill Input")
        }
        else {

            const start = Number(startNumber)
            const end = Number(endNumber)
            setLoading(true);
            axios.post(`${BACKEND_URL}/products/shopify/export`, {
                category: category,
                storeName: storeName,
                storeUrl: storeUrl,
                Accesstoken: accessToken,
                startproduct: start,
                endproduct: end,
            })
                .then((response) => {
                    if (response.data.status === 200) {
                        setEndNumber("")
                        setStartNumber("")
                        setStoreName("")
                        setStoreUrl("")
                        setAccessToken("")
                        toast.success("Successfully Product Integrate")
                    }
                    setLoading(false);
                    console.log(response)

                })
                .catch((error) => {
                    toast.error("Submit Error")
                    // console.error("Error Submit:", error);
                    setLoading(false);
                });
        }
    }
    const handleOrderSubmit = () => {
        if (!orderAccessToken || !email || !orderAccessToken) {
            toast.error("Fill Input")
        }
        else {
            setOrderLoading(true);
            axios.post(`${BACKEND_URL}/orders/webhooks/create-store`, {
                email: email,
                storeName: orderStoreName,
                Accesstoken: orderAccessToken,
            })
                .then((response) => {
                    if (response.status === 200) {
                        setEmail("")
                        setOrderAccessToken("")
                        setOrderStoreName("")
                        toast.success("Successfully Order Create")
                        setWebhook(response.data.webhook)
                    }
                    setOrderLoading(false);
                    console.log(response)
                })
                .catch((error) => {
                    toast.error("Submit Error")
                    // console.error("Error Submit:", error);
                    setOrderLoading(false);
                });
        }
    }

    return (
        <>
            {login ? <LoginPopup overlayClick={openLoginModel} /> : null}
            <Navbar openSidebar={openSidebar} AllCategory={AllCategory} WishlistData={WishlistData} searchQuery={searchQuery} setSearchQuery={setSearchQuery} AllProducts={AllProducts} />
            <Sidebar closeSidebar={closeSidebar} sidebarOpen={sidebarOpen} />
            <div className="store-intgrate-maindiv">
                <div className="store-intgrate">
                    <div className="store-heading-container">
                        <h3>Store Integration</h3>
                        {/* <h6>Last Modified: July 22 24</h6> */}
                    </div>
                    <div className="store-intgrate-div">
                        {/* <p style={{ fontSize: "14px", fontWeight: "500", marginTop: "10px" }}>
                            <strong style={{ paddingTop: "0.5%" }}>Order Creation: </strong>
                            <span style={{ wordBreak: "break-all" }}>https://backend.desertsdropshipper.com/api/v1/orders/webhooks/shopify-order</span>
                        </p> */}
                        <p style={{ fontSize: "14px", fontWeight: "500", marginTop: "10px" }}>
                            <strong style={{ paddingTop: "0.5%" }}>Order Tracking: </strong>
                            <a href="https://www.imile.com/AE-en/track/" target="_blank" style={{ wordBreak: "break-all", textDecoration: "none" }}>https://www.imile.com/AE-en/track/</a>{" "},{" "}
                            <a href="https://www.c3xpress.com/" target="_blank" style={{ wordBreak: "break-all", textDecoration: "none" }}>https://www.c3xpress.com/</a>
                        </p>
                        <h6 style={{ fontSize: "18px", fontWeight: "700", width: "100%", textAlign: "center", marginBottom: "20px" }}>Order Creation:</h6>
                        <input placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        <input placeholder="Store Name" value={orderStoreName} onChange={(e) => setOrderStoreName(e.target.value)} />
                        <input placeholder="Access Token" value={orderAccessToken} onChange={(e) => setOrderAccessToken(e.target.value)} />
                        <button onClick={() => handleOrderSubmit()} style={{ border: 0, background: "#E86029", color: "#fff", fontSize: "16px", fontWeight: "600", padding: "10px 20px", borderRadius: "8px", margin: "0 auto" }}>{orderLoading ? <CircularProgress size={20} sx={{ color: "#fff" }} /> : "Create"}</button>
                        {webhooks ? <p style={{ fontSize: "14px", fontWeight: "500", marginTop: "10px", width: "100%" }}>
                            <strong style={{ paddingTop: "0.5%" }}>Webhook Url: </strong>{webhooks}
                        </p>
                            : null}
                        <h6 style={{ fontSize: "18px", fontWeight: "700", width: "100%", textAlign: "center", marginBottom: "20px" }}>Product Integrate:</h6>
                        <input placeholder="Store Name" value={storeName} onChange={(e) => setStoreName(e.target.value)} />
                        <input placeholder="Store Url" value={storeUrl} onChange={(e) => setStoreUrl(e.target.value)} />
                        <input placeholder="Access Token" value={accessToken} onChange={(e) => setAccessToken(e.target.value)} />
                        <div style={{
                            width: "48%",
                            border: "1px solid gray",
                            borderRadius: "8px",
                            padding: "10px",
                            margin: 0
                        }}>
                            <Dropdowns categories={AllCategory?.map(category => category.name)} onSelect={handleCategorySelect} />
                        </div>
                        <p style={{ fontSize: "18px", fontWeight: "600", marginTop: "10px", width: "100%", textAlign: "center" }}>
                            Max 20 Product
                        </p>
                        <input placeholder="Start Number" value={startNumber} onChange={(e) => setStartNumber(e.target.value)} />
                        <input placeholder="End Number" value={endNumber} onChange={(e) => setEndNumber(e.target.value)} />

                        <button onClick={() => handleSubmit()} style={{ border: 0, background: "#E86029", color: "#fff", fontSize: "16px", fontWeight: "600", padding: "10px 20px", borderRadius: "8px", margin: "0 auto" }}>{loading ? <CircularProgress size={20} sx={{ color: "#fff" }} /> : "Product Sync"}</button>
                        {/* <h6 style={{ fontSize: "18px", fontWeight: "700" }}>Store Integration for Deserts Dropshipper API</h6>
                        <h6 style={{ fontSize: "18px", fontWeight: "700" }}>1. Product APIs</h6> */}

                        {/* <p style={{ fontSize: "14px", fontWeight: "500", marginTop: "10px" }}>
                            <strong style={{ paddingTop: "0.5%" }}>Get All Products: </strong>
                            <span style={{ wordBreak: "break-all" }}>https://backend.desertsdropshipper.com/api/v1/products</span>
                            <FontAwesomeIcon icon={faClipboard} color="black" style={{ marginLeft: "20px", cursor: "pointer" }}
                                onClick={() => copyToClipboard('https://backend.desertsdropshipper.com/api/v1/products')} />
                        </p>

                        <p style={{ fontSize: "14px", fontWeight: "500", marginTop: "10px" }}>
                            <strong style={{ paddingTop: "0.5%" }}>Get Product By Category: </strong>
                            <span style={{ wordBreak: "break-all" }}> https://backend.desertsdropshipper.com/api/v1/products/category/:category</span>
                            <FontAwesomeIcon icon={faClipboard} color="black" style={{ marginLeft: "20px", cursor: "pointer" }}
                                onClick={() => copyToClipboard('https://backend.desertsdropshipper.com/api/v1/products/category/:category')} />
                        </p>

                        <p style={{ fontSize: "14px", fontWeight: "500", marginTop: "10px" }}>
                            <strong style={{ paddingTop: "0.5%" }}>Get Product By ID: </strong>
                            <span style={{ wordBreak: "break-all" }}> https://backend.desertsdropshipper.com/api/v1/products/:id</span>
                            <FontAwesomeIcon icon={faClipboard} color="black" style={{ marginLeft: "20px", cursor: "pointer" }}
                                onClick={() => copyToClipboard('https://backend.desertsdropshipper.com/api/v1/products/:id')} />
                        </p>

                        <h6 style={{ fontSize: "18px", fontWeight: "700" }}>2. Category APIs</h6>

                        <p style={{ fontSize: "14px", fontWeight: "500", marginTop: "10px" }}>
                            <strong style={{ paddingTop: "0.5%" }}>Get All Categories: </strong>
                            <span style={{ wordBreak: "break-all" }}>https://backend.desertsdropshipper.com/api/v1/categories</span>
                            <FontAwesomeIcon icon={faClipboard} color="black" style={{ marginLeft: "20px", cursor: "pointer" }}
                                onClick={() => copyToClipboard('https://backend.desertsdropshipper.com/api/v1/categories')} />
                        </p>

                        <p style={{ fontSize: "14px", fontWeight: "500", marginTop: "10px" }}>
                            <strong style={{ paddingTop: "0.5%" }}>Get Category By ID: </strong>
                            <span style={{ wordBreak: "break-all" }}> https://backend.desertsdropshipper.com/api/v1/categories/:id</span>
                            <FontAwesomeIcon icon={faClipboard} color="black" style={{ marginLeft: "20px", cursor: "pointer" }}
                                onClick={() => copyToClipboard('https://backend.desertsdropshipper.com/api/v1/categories/:id')} />
                        </p>
                        <h6 style={{ fontSize: "18px", fontWeight: "700" }}>3. Create Order APIs</h6>
                        <p style={{ fontSize: "14px", fontWeight: "500", marginTop: "10px" }}>
                            <strong style={{ paddingTop: "0.5%" }}>Post Api: </strong>
                            <span style={{ wordBreak: "break-all" }}> https://backend.desertsdropshipper.com/api/v1/orders</span>
                            <FontAwesomeIcon icon={faClipboard} color="black" style={{ marginLeft: "20px", cursor: "pointer" }}
                                onClick={() => OrderCopyToClipboard('https://backend.desertsdropshipper.com/api/v1/categories/:id')} />
                        </p> */}
                    </div>
                </div>
            </div>
            {/* {orderModal ?
                <div className="login-popup-modal">
                    <div className="overlay-modal" onClick={closeOrderModel}>
                    </div>
                    <div className="orderapi-popup">
                        <h3 style={{ textAlign: "center", marginBottom: "10px" }}>Require Token</h3>
                        <p className="header-token">
                            {`headers: {
                            token:${token?.token}
                            }
                            `}
                        </p>
                        <h3 style={{ textAlign: "center", marginBottom: "10px" }}>Mandatory Fields</h3>
                        <p>
                            1: firstName*
                            ,
                            2: lastName*
                            ,
                            3: email*
                            ,
                            4: phoneNumber*
                            ,
                            5: profitMargin*
                            ,
                            6: company*
                            ,
                            7: address1*
                            ,
                            8: address2*
                            ,
                            9: postCode*
                            ,
                            10:  country: 'UAE'*
                            ,
                            11:  city*
                            ,
                            12:  totalAmount*
                            ,
                            13: {`products: [{
                        id: product.id,
                        quantity: product.quantity,
                    }]`},*
                        </p>
                    </div>
                </div>
                : null} */}
            <Footer instagramData={instagramData} />
        </>
    );
}

export default withAppContext(StoreIntegration);
